// Wallet API

import { GetAPIURL, RequestParams } from "@/utils/request";

export interface WalletInfo {
    address: string;
    kind: "unverified" | "lender" | "borrower";
}

export class WalletAPI {
    public static GetWallet(): RequestParams<WalletInfo> {
        return {
            method: "GET",
            url: GetAPIURL("/wallet"),
        };
    }

    public static CreateWallet(password: string, privateKeyHex: string): RequestParams<WalletInfo> {
        return {
            method: "POST",
            url: GetAPIURL("/wallet"),
            json: {
                password: password,
                private_key: privateKeyHex,
            },
        };
    }

    public static ChangePassword(oldPassword: string, newPassword: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/wallet/password"),
            json: {
                password: oldPassword,
                new_password: newPassword,
            },
        };
    }

    public static Export(password: string): RequestParams<{ private_key: string }> {
        return {
            method: "POST",
            url: GetAPIURL("/wallet/export"),
            json: {
                password: password,
            },
        };
    }
}
