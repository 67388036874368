// Notifications API

import { GenerateURIQuery, GetAPIURL, RequestParams } from "@/utils/request";

export interface NotificationData_KYC_Accepted {
    type: "kyc_accepted";
}

export interface NotificationData_KYC_Rejected {
    type: "kyc_rejected";
    reason: string;
}

export interface NotificationData_Offer_Created {
    type: "offer_new";
    reqId: string;
    offerId: string;
    reqTitle: string;
}

export interface NotificationData_Offer_Accepted {
    type: "offer_accepted";
    reqId: string;
    offerId: string;
    reqTitle: string;
}

export interface NotificationData_Offer_Message {
    type: "offer_message";
    reqId: string;
    offerId: string;
    reqTitle: string;
    messageId: string;
    lenderId: string;
    borrowerId: string;
}


export interface NotificationData_Offer_Contract {
    type: "offer_contract";
    reqId: string;
    offerId: string;
    reqTitle: string;
    contractId: string;
}

export interface NotificationData_Offer_Contract_Signature {
    type: "contract_signed";
    contractId: string;
}

export interface NotificationData_Initial_Transfer {
    type: "initial_transfer";
    contractId: string;
}

export interface NotificationData_Period_Payment {
    type: "period_payment";
    contractId: string;
}

export interface NotificationData_Period_Payment_Canceled {
    type: "period_payment_canceled";
    contractId: string;
}

export interface NotificationData_Refund_Payment {
    type: "refund_payment";
    contractId: string;
}

export interface NotificationData_Contract_Signed {
    type: "contract_signed";
    contractId: string;
}

export interface NotificationData_Contract_Deleted {
    type: "contract_deleted";
    contractId: string;
}

export interface NotificationData_Request_Closed {
    type: "request_closed";
    reqId: string;
    offerId: string;
    reqTitle: string;
}

export interface NotificationData_Offer_Closed {
    type: "offer_closed";
    reqId: string;
    offerId: string;
    reqTitle: string;
}

export interface NotificationData_Unknown {
    type: "unknown";
}

export type NotificationData = NotificationData_KYC_Accepted | NotificationData_KYC_Rejected | NotificationData_Offer_Created | NotificationData_Offer_Accepted | NotificationData_Offer_Contract | NotificationData_Offer_Contract_Signature | NotificationData_Offer_Message | NotificationData_Request_Closed | NotificationData_Offer_Closed | NotificationData_Unknown | NotificationData_Initial_Transfer | NotificationData_Period_Payment | NotificationData_Period_Payment_Canceled | NotificationData_Refund_Payment | NotificationData_Contract_Signed | NotificationData_Contract_Deleted;

export interface PlatformNotification {
    id: string;
    date: number;
    data: NotificationData;
}

export class NotificationsAPI {
    public static GetStatus(): RequestParams<{ unread: number, total: number }> {
        return {
            method: "GET",
            url: GetAPIURL("/notifications"),
        };
    }

    public static List(oldest: number): RequestParams<PlatformNotification[]> {
        return {
            method: "GET",
            url: GetAPIURL("/notifications/list") + GenerateURIQuery({ oldest: oldest }),
        };
    }

    public static Read(timestamp: number): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/notifications/read"),
            json: {
                timestamp: timestamp
            },
        };
    }

    public static Delete(id: string): RequestParams<void> {
        return {
            method: "DELETE",
            url: GetAPIURL("/notifications/id/" + encodeURIComponent(id)),
        };
    }
}
